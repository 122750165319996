import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import {
    Alert,
    Snackbar,
    Stack,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { CURRENCY, CURRENCY_SIGN } from '../../common/constants';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Element, scroller } from 'react-scroll';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import useProductData from '../../common/hooks/useProductData';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { addItemToCartAction } from '../../service/action/cart/cartActions';
import {
    selectCartIsLoading,
    selectCartItems,
    selectCartDrawer,
} from '../../service/selectors/cartSelectors';
import { appActions } from '../../service/action';
import ShareMenu from '../ShareMenu';
import SizeChartGuide from '../SizeChartGuide';
import { RewardPointsContainer } from '../../view/cart/CartStyle';
import PointsIcon from '../../assets/images/points.svg';
import RecentSold from '../../assets/images/recent-sold.svg';
import Views from '../../assets/images/views.svg';
import { useMutation } from '@tanstack/react-query';
import PreviewImage from './PreviewImage';
import AddToCart from './AddToCart';
import Options from './Options';
import Offer from '../../view/coupon/Offer';
import CarouselView from './CarouselView';
import {
    formatAmount,
    getDailyProductSoldCount,
    getDailyProductViewCount,
    getSelectedVariantFromProduct,
} from '../../common/utils/productUtils';
import { getPublicCoupons } from '../../service/api/coupons';
import {
    AllTaxText,
    AlterStyle,
    AvailableCouponBox,
    AvailableCouponText,
    CollapseStyle,
    CustomRenderThumbStyle,
    DescriptionText,
    DiscountBox,
    DiscountText,
    KnowMoreStyle,
    PointsStyle,
    ProductViewCarouselGrid,
    ProductViewDescription,
    ProductViewTitle,
    ProductViewTitleBox,
    SoldNumberBox,
    SoldNumberText,
    RewardPointsText,
    SoldOutStyle,
    VariantOriginalPrice,
    VariantSellingPrice,
    ViewCartBox,
    ViewCartButton,
    ViewCartText,
    ViewNumberText,
} from './ProductStyle';
import DeliveryInfo from './DeliveryInfo';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
        display: 'inline-flex',
        padding: '6px 12px 6px 10px',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '12px',
        color: 'var(--Accent-White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Euclid-Circular-A-Regular',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        cursor: 'pointer',
    },
}));

const ProductView = ({
    productData,
    setOpenModal,
    previewImage,
    setPreviewImage,
    productOptions,
    sizeChartImages,
    setSizeChartImages,
}: any) => {
    const {
        name,
        originalPrice,
        media,
        shortDescription,
        discountValue,
        sellingPrice,
        id,
        pid,
        discountMetric,
        earnableRewardPoints,
    } = productData;

    const { data: product, refetch: getProductData } = useProductData(pid);

    const dispatch = useDispatch();

    const [productQuantity, setProductQuantity] = useState(1);
    const [isCouponsAvailable, setIsCouponsAvailable] = useState(true);
    const [options, setOptions] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedVariantIsAvailable, setSelectedVariantIsAvailable] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const isMobile = useCheckMobileScreen();
    const [selectedItem, setSelectedItem] = useState(0);

    const [variantPriceData, setVariantPriceData] = useState({
        variantId: null,
        variantMedia: media.length > 0 ? media[0] : null,
        variantIsAvailable: false,
        variantOriginalPrice: originalPrice,
        variantSellingPrice: sellingPrice,
        variantDiscountValue: discountValue,
        variantDiscountMetric: discountMetric,
        variantEarnableRewardPoints: earnableRewardPoints,
    });

    const [defaultPref, setDefaultPref] = useState({
        baseInfo: productData,
        id: id,
        finalPrice: sellingPrice ? sellingPrice : originalPrice,
        discountValue: discountValue,
        selectedVariant: [],
        color: null,
        quantity: 1,
    });

    const cartLoading = useSelector(selectCartIsLoading);
    const cartItems = useSelector(selectCartItems);

    const [addAction, setAddAction] = useState(false);
    const cartDrawer = useSelector(selectCartDrawer);

    const getcouponsMut = useMutation({
        mutationFn: (data: any) => getPublicCoupons(data),
        onSuccess: (res: any) => {
            setCoupons(res?.data?.data.slice(0, 5) || []);
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET COUPONS', error);
        },
    });

    useEffect(() => {
        setPreviewImage('');
        setOptions(productOptions);
    }, []);

    const toggleDrawer = (anchor: any, open: any) => {
        dispatch({
            type: `${appActions.SET_CART_DRAWER}_SUCCESS`,
            payload: { ...cartDrawer, [anchor]: open },
        });
    };

    const handleCloseSnackbar = (_: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (addAction) {
            if (!isMobile) {
                setOpenModal(false);
                dispatch({
                    type: `${appActions.SET_CART_DRAWER}_SUCCESS`,
                    payload: { ...cartDrawer, right: true },
                });
            }
            setSnackbarOpen(true);
            setAddAction(false);
            setProductQuantity(1); // reset qty
        }
    }, [cartItems]);

    const handleAddToCart = () => {
        if (product) {
            let formData = {};

            formData = {
                ...formData,
                quantity: productQuantity,
                product_id: defaultPref.id,
                product_variant_option_id: variantPriceData?.variantId,
            };

            dispatch(addItemToCartAction({ data: formData }));
            setAddAction(true);
        }
    };

    const setProductData = (productData: any) => {
        if (productData) {
            setPreviewImage('');
            setOptions(productData?.options);
            let selectedVariants: { variant: any; value: any }[] = [];
            if (productData.options?.length > 0) {
                productData.options.forEach((o: any) => {
                    if (o.values && o.values.length > 0) {
                        const v = { variant: o.name, value: o.values[0] };
                        selectedVariants.push(v);
                    }
                });
            }
            // @ts-ignore
            setDefaultPref(prev => ({
                ...prev,
                baseInfo: productData,
                id: productData.pid ? productData.pid : productData.id,
                color: null,
                quantity: 1,
                selectedVariant: selectedVariants,
            }));
            setProductQuantity(1);
        }
    };

    useEffect(() => {
        if (product) {
            setProductData(product);
        }
    }, [product]);

    useEffect(() => {
        setOptions(productOptions);
        if (product) {
            setProductData(productData);
        }
        setPreviewImage('');
        getProductData(pid);
    }, [pid]);

    useEffect(() => {
        const variant = getSelectedVariantFromProduct({
            product,
            selectedVariant: defaultPref.selectedVariant,
        });

        if (!variant) {
            return;
        }
        setSelectedVariantIsAvailable(variant ? variant?.isAvailable : true);

        setVariantPriceData({
            variantId: variant?.id,
            variantMedia: variant?.media ? variant?.media : media.length > 0 ? media[0] : null,
            variantIsAvailable: variant?.isAvailable || !variant,
            variantOriginalPrice: variant?.originalPrice || originalPrice,
            variantSellingPrice: variant?.sellingPrice || sellingPrice,
            variantDiscountValue: variant?.discountValue || discountValue,
            variantDiscountMetric: variant?.discountMetric || discountMetric,
            variantEarnableRewardPoints: variant?.earnableRewardPoints,
        });
        /* Get product coupons */
        getcouponsMut.mutate({
            page: 1,
            limit: 5,
            products: pid,
            // productVariantOptions: variant?.id,
        });
    }, [defaultPref]);

    const updateCurrentSlide = (index: any) => {
        if (selectedItem !== index) {
            setSelectedItem(index);
        }
    };

    const getVariantValue = (name: any, values: any) => {
        const obj: any = defaultPref.selectedVariant;
        if (obj && obj.length > 0) {
            const option = obj.find((item: { variant: any }) => item.variant === name);
            return option?.value?.name;
        }
        return values.length > 0 ? values[0]?.name : {};
    };

    const customRenderThumb = (children: any) =>
        children.map(({ props }: any, index: number) => {
            if (props.children.type === 'img') {
                return props.children;
            } else {
                return (
                    <Box key={index} sx={CustomRenderThumbStyle}>
                        <PlayCircleOutlineIcon sx={{ color: 'white' }} fontSize="large" />
                    </Box>
                );
            }
        });

    const _earnableRewardPoints = Math.floor(variantPriceData?.variantEarnableRewardPoints || 0);

    // if user has clicked at any of the images, let's preview that one!
    if (previewImage) {
        return <PreviewImage previewImage={previewImage} />;
    }

    if (sizeChartImages.length) {
        return <SizeChartGuide sizeChartImages={sizeChartImages} />;
    }

    return (
        <DialogContent sx={{ padding: { xs: '1rem', md: '1.5rem' } }}>
            <Grid container>
                <Grid xs={12} md={6} sx={ProductViewCarouselGrid}>
                    <ShareMenu productId={productData?.pid} />
                    <CarouselView
                        selectedItem={selectedItem}
                        updateCurrentSlide={updateCurrentSlide}
                        customRenderThumb={customRenderThumb}
                        media={media}
                        setPreviewImage={setPreviewImage}
                    />
                    {!isMobile && <DeliveryInfo />}
                </Grid>

                <Grid xs={12} md={6}>
                    <DialogContent sx={{ py: 0, px: isMobile ? 0 : null }}>
                        <Stack spacing={'1.75rem'}>
                            <Box sx={ProductViewTitleBox}>
                                <Box sx={SoldNumberBox}>
                                    <img
                                        src={RecentSold}
                                        alt=""
                                        style={{
                                            width: '1rem',
                                            height: '1rem',
                                        }}
                                    />
                                    <Typography sx={SoldNumberText}>
                                        {getDailyProductSoldCount(pid)}+ sold recently
                                    </Typography>
                                </Box>

                                <Typography sx={ProductViewTitle}>{name ? name : ''}</Typography>
                                <Typography sx={ProductViewDescription}>
                                    {shortDescription}
                                </Typography>
                                {isMobile && (
                                    <Link
                                        to={'#desc'}
                                        onClick={() => {
                                            scroller.scrollTo('desc', {
                                                duration: 1000,
                                                delay: 100,
                                                smooth: true,
                                                containerId: 'scroll-container',
                                            });
                                        }}
                                        style={KnowMoreStyle}
                                    >
                                        Know More
                                    </Link>
                                )}
                            </Box>
                            {!selectedVariantIsAvailable && (
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography gutterBottom sx={SoldOutStyle}>
                                        {`Sold Out`}
                                    </Typography>
                                </Stack>
                            )}
                            <Stack spacing={'0.62rem'}>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography gutterBottom sx={VariantSellingPrice}>
                                        {`${CURRENCY_SIGN}${formatAmount(
                                            variantPriceData.variantSellingPrice
                                        )}`}
                                    </Typography>
                                    {variantPriceData.variantDiscountValue > 0 && (
                                        <Typography gutterBottom sx={VariantOriginalPrice}>
                                            {`${CURRENCY_SIGN}${variantPriceData.variantOriginalPrice}`}
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    {variantPriceData?.variantDiscountValue > 0 && (
                                        <Box sx={DiscountBox}>
                                            <Typography sx={DiscountText}>
                                                {variantPriceData.variantDiscountMetric === 'INR'
                                                    ? CURRENCY
                                                    : ' '}{' '}
                                                {variantPriceData.variantDiscountValue}
                                                {variantPriceData.variantDiscountMetric ===
                                                'PERCENTAGE'
                                                    ? '% '
                                                    : ' '}
                                                Off
                                            </Typography>
                                        </Box>
                                    )}
                                    <Typography gutterBottom sx={AllTaxText}>
                                        incl. of all taxes
                                    </Typography>
                                </Stack>
                            </Stack>
                            {_earnableRewardPoints > 0 && (
                                <Stack alignItems={'flex-start'}>
                                    <RewardPointsContainer style={{ borderRadius: '90px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '6px',
                                            }}
                                        >
                                            <Typography variant="body1" sx={RewardPointsText}>
                                                Earn
                                                <PointsStyle>
                                                    <img
                                                        src={PointsIcon}
                                                        alt=""
                                                        style={{
                                                            width: '1rem',
                                                            height: '1rem',
                                                        }}
                                                    />
                                                    {_earnableRewardPoints.toLocaleString('en-IN')}
                                                </PointsStyle>
                                                SN points with this purchase{' '}
                                            </Typography>
                                            <Link
                                                to="#"
                                                onClick={() => window.open('/rewards', '_blank')}
                                                style={{ height: '18px' }}
                                            >
                                                <BootstrapTooltip
                                                    title={'Learn more'}
                                                    placement="top"
                                                >
                                                    <InfoOutlinedIcon
                                                        sx={{
                                                            fontSize: '1.25rem',
                                                            color: '#0A69D9',
                                                        }}
                                                    />
                                                </BootstrapTooltip>
                                            </Link>
                                        </Box>
                                    </RewardPointsContainer>
                                </Stack>
                            )}
                            {options?.length > 0 ? (
                                <Stack gap={'12px'}>
                                    {options.map(({ name, values }: any, i: number) => (
                                        <Options
                                            index={i}
                                            options={options}
                                            name={name}
                                            values={values}
                                            getVariantValue={getVariantValue}
                                            productData={productData}
                                            setSizeChartImages={setSizeChartImages}
                                            defaultPref={defaultPref}
                                            setDefaultPref={setDefaultPref}
                                            product={product}
                                        />
                                    ))}
                                </Stack>
                            ) : null}
                            <Box sx={SoldNumberBox}>
                                <img
                                    src={Views}
                                    alt=""
                                    style={{
                                        width: '1rem',
                                        height: '1rem',
                                    }}
                                />
                                <Typography sx={ViewNumberText}>
                                    {getDailyProductViewCount(pid)} people are viewing this product
                                    right now.
                                </Typography>
                            </Box>

                            {!selectedVariantIsAvailable && options?.length > 0 ? (
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography gutterBottom sx={SoldOutStyle}>
                                        {`Sold Out`}
                                    </Typography>
                                </Stack>
                            ) : (
                                <AddToCart
                                    productQuantity={productQuantity}
                                    setProductQuantity={setProductQuantity}
                                    cartLoading={cartLoading}
                                    selectedVariantIsAvailable={selectedVariantIsAvailable}
                                    handleAddToCart={handleAddToCart}
                                />
                            )}
                            {isMobile && (
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={3000}
                                    onClose={handleCloseSnackbar}
                                >
                                    <Alert
                                        severity="success"
                                        icon={<CheckCircleIcon sx={{ color: '#FFF' }} />}
                                        sx={AlterStyle}
                                    >
                                        <Stack sx={ViewCartBox}>
                                            <Typography sx={ViewCartText}>
                                                Product added to cart
                                            </Typography>

                                            <Button
                                                onClick={() => toggleDrawer('right', true)}
                                                sx={ViewCartButton}
                                            >
                                                View cart
                                            </Button>
                                        </Stack>
                                    </Alert>
                                </Snackbar>
                            )}
                            {coupons?.length > 0 && (
                                <Stack gap={'0.5rem'}>
                                    <Box
                                        sx={AvailableCouponBox}
                                        onClick={() => setIsCouponsAvailable(prev => !prev)}
                                    >
                                        <Typography sx={AvailableCouponText}>Top Offers</Typography>
                                    </Box>
                                    <Box sx={CollapseStyle}>
                                        {coupons.map((coupon: any, index: any) => (
                                            <Offer coupon={coupon} key={index} index={index} />
                                        ))}
                                    </Box>
                                </Stack>
                            )}
                            {isMobile && <DeliveryInfo />}
                            <Box component={Element} id="test" name="desc">
                                <Typography sx={DescriptionText}>Description</Typography>
                                <div
                                    className="description-wrapper"
                                    dangerouslySetInnerHTML={{ __html: productData.description }}
                                    style={{ marginBottom: isMobile ? '65px' : '' }}
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default ProductView;
