import {
    Card,
    CardActions,
    Typography,
    Box,
    Stack,
    CardActionArea,
    useTheme,
    Button,
} from '@mui/material';
import { CURRENCY, CURRENCY_SIGN } from '../common/constants';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { useSelector } from 'react-redux';
import ProductDetailsMod, { getProductFromState } from './Product/ProductDetailsMod';
import { useEffect, useState } from 'react';
import MediaPreview from './MediaPreview';
import { formatAmount } from '../common/utils/productUtils';

type Props = {
    productData: any;
    productsParams: any;
    resetQueryFilter: any;
};

export default function ProductCard({ productData, productsParams, resetQueryFilter }: Props) {
    const { name, originalPrice, media, sellingPrice, discountValue, id, options, discountMetric } =
        productData;
    const { search_keyword } = productsParams;
    const theme = useTheme();
    const { timestamp } = useSelector((state: any) => state.commonReducer);
    const [cart, setCart] = useState(() => {
        const storedData = localStorage.getItem('cart');
        return storedData ? JSON.parse(storedData) : [];
    });
    let isMobile = useCheckMobileScreen();
    useEffect(() => {
        setCart(() => {
            const storedData = localStorage.getItem('cart');
            return storedData ? JSON.parse(storedData) : [];
        });
    }, [timestamp]);

    const [openProductModal, setOpenProductModal] = useState(false);

    useEffect(() => {
        if (productData.pid === search_keyword) {
            setOpenProductModal(true);
            resetQueryFilter();
        }
    }, []);
    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    border: 'none',
                    height: isMobile ? '320px' : '370px',
                }}
            >
                <CardActionArea
                    onClick={() => setOpenProductModal(true)}
                    sx={{ height: '100%', borderRadius: '1rem', border: '1px solid #ececec' }}
                >
                    {discountValue > 0 && (
                        <Box
                            p={1}
                            sx={{
                                background: theme.palette.primary.main,
                                position: 'absolute',
                                top: 10,
                                borderRadius: '0.5rem',
                                left: 10,
                            }}
                        >
                            <Typography variant="body2" fontSize={'10px'}>
                                {discountMetric === 'INR' ? CURRENCY : ' '} {discountValue}
                                {discountMetric === 'PERCENTAGE' ? '% ' : ' '}
                                Off
                            </Typography>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            gap: '1rem',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                height: isMobile ? '144px' : '195px',
                                justifyContent: 'center',
                            }}
                        >
                            <MediaPreview media={media} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: isMobile ? '150px' : '159px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={'0.375rem'}
                                padding={'0 10px'}
                            >
                                <Typography
                                    sx={
                                        isMobile
                                            ? {
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                  fontSize: '0.875rem',
                                                  fontStyle: 'normal',
                                                  display: '-webkit-box',
                                                  WebkitBoxOrient: 'vertical',
                                                  WebkitLineClamp: 2,
                                                  fontWeight: 400,
                                              }
                                            : {
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                  fontSize: '1rem',
                                                  fontStyle: 'normal',
                                                  display: '-webkit-box',
                                                  WebkitBoxOrient: 'vertical',
                                                  WebkitLineClamp: 2,
                                                  fontWeight: 500,
                                              }
                                    }
                                >
                                    {name}
                                </Typography>

                                <Stack flexDirection={'row'}>
                                    <Typography
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 1,
                                            color: 'var(--Accent-Blue, #0A69D9)',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Euclid-Circular-A-Regular',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        {options.map((option: any, index: any) => {
                                            const name =
                                                option.values.length > 1
                                                    ? `${option.name.toLowerCase()}s`
                                                    : option.name.toLowerCase();

                                            let separator = '';
                                            if (index > 0 && index < options.length - 1) {
                                                separator = ', ';
                                            }
                                            if (index === options.length - 2) {
                                                separator = ' & ';
                                            }

                                            return (
                                                <>
                                                    {option.values.length} {name}
                                                    {separator}
                                                </>
                                            );
                                        })}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Stack>
                                <CardActions
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Stack
                                        direction={'row'}
                                        spacing={1}
                                        alignContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography
                                            gutterBottom
                                            sx={
                                                isMobile
                                                    ? {
                                                          overflow: 'hidden',
                                                          color: '#14141E',
                                                          textOverflow: 'ellipsis',
                                                          fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                          fontSize: '1rem',
                                                          fontStyle: 'normal',
                                                          lineHeight: 'normal',
                                                      }
                                                    : {
                                                          fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                          fontSize: '1rem',
                                                          fontStyle: 'normal',
                                                          lineHeight: '1.5rem',
                                                      }
                                            }
                                        >
                                            {`${CURRENCY_SIGN}${formatAmount(
                                                sellingPrice
                                                    ? sellingPrice
                                                    : originalPrice -
                                                          (originalPrice * discountValue) / 100
                                            )}`}
                                        </Typography>

                                        {getProductFromState(cart, id) instanceof Array === false &&
                                        isMobile
                                            ? null
                                            : discountValue > 0 && (
                                                  <Typography
                                                      gutterBottom
                                                      sx={
                                                          isMobile
                                                              ? {
                                                                    overflow: 'hidden',
                                                                    color: '#14141E',
                                                                    textOverflow: 'ellipsis',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                    fontSize: '0.875rem',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    opacity: '0.4',
                                                                    lineHeight: '1.5rem',
                                                                    textDecoration: 'line-through',
                                                                }
                                                              : {
                                                                    overflow: 'hidden',
                                                                    color: '#14141E',
                                                                    textOverflow: 'ellipsis',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                    fontSize: '1rem',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    opacity: '0.4',
                                                                    lineHeight: '1.5rem',
                                                                    textDecoration: 'line-through',
                                                                }
                                                      }
                                                  >
                                                      {`${CURRENCY_SIGN}${originalPrice}`}
                                                  </Typography>
                                              )}
                                    </Stack>
                                </CardActions>
                                <Stack sx={{ width: '100%', margin: '0!important' }}>
                                    <Button
                                        size="large"
                                        fullWidth
                                        disableElevation
                                        sx={{
                                            border: '1px solid var(--yellow-pri, #FFD600)',
                                            background: '#FFD600',
                                            color: '#000',
                                            fontFamily: 'Euclid-Circular-A-Light',
                                            padding: '11px 16px',
                                            height: '40px',
                                            borderRadius: '0 0 1rem 1rem',
                                            '&:hover': {
                                                background: '#FFD600',
                                            },
                                        }}
                                    >
                                        {'Add to cart'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </CardActionArea>
            </Card>

            {openProductModal && (
                <ProductDetailsMod
                    isOpen={openProductModal}
                    productData={productData}
                    productOptions={productData.options}
                    setOpenModal={setOpenProductModal}
                    resetQueryFilter={resetQueryFilter}
                />
            )}
        </>
    );
}
