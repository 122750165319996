import {
    Box,
    Button,
    ListItemText,
    Typography,
    Stack,
    Tooltip,
    TooltipProps,
    tooltipClasses,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { RewardsPointsLabel, PriceStyle } from '../order/OrderStyles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../service/selectors/userSelector';
import { useMutation } from '@tanstack/react-query';
import RedeemRewardModal from './RedeemRewardModal';
import { redeemRequest } from '../../service/api/rewards';
import RedeemSuccessModal from '../coupon/RedeemSuccessModal';
import { totalPointsSelector } from '../../service/selectors/userSelector';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import Icons from '../../component/icon';
import { CURRENCY_SIGN } from '../../common/constants';
import {
    Container,
    Main,
    PointContainer,
    PointGrid,
    PointMainGrid,
    PointText,
    ShopNowButton,
    SpendBox,
    SpendText,
} from './RewardStyle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
        display: 'inline-flex',
        padding: '6px 12px 6px 10px',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '12px',
        color: 'var(--Accent-White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Euclid-Circular-A-Regular',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        cursor: 'pointer',
    },
}));

export default function RewardCard({ reward, isMyRewards }: any) {
    const navigate = useNavigate();
    const user = useSelector(userSelector);
    const isMobile = useCheckMobileScreen();
    const [couponData, setCouponData] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const currentPoints = useSelector(totalPointsSelector);
    const [openCouponModal, setOpenCouponModal] = useState(false);
    const [selectedCouponCode, setSelectedCouponCode] = useState('');

    const handleClose = () => setOpenModal(false);
    const handleCouponCodeClose = () => setOpenCouponModal(false);
    const handleCouponCodeOpen = () => setOpenCouponModal(true);

    const sendOTPFormMut = useMutation({
        mutationFn: (data: any) => redeemRequest(data),
        onSuccess: (res: any) => {},
    });

    const sendOTP = () => {
        const result = sendOTPFormMut.mutate({});
        return result;
    };

    // point should be calculated for login user
    const requiredPoints = reward.points_value - (isMyRewards ? currentPoints : 0);

    const isButtonInactive = requiredPoints > 0 || !isMyRewards;

    const redeemPoints = () => {
        if (requiredPoints > 0 || !isMyRewards || reward.name.includes('service')) {
            return null;
        } else {
            setOpenModal(true);
            setSelectedCouponCode(reward?._id || '');
        }
    };

    return (
        <>
            {requiredPoints > 0 && (
                <Box sx={SpendBox}>
                    <Typography sx={SpendText}>
                        <Icons name="moneyStack" />{' '}
                        {`Spend ${CURRENCY_SIGN + requiredPoints} to unlock next reward`}
                    </Typography>
                    <Button sx={ShopNowButton} onClick={() => navigate('/products')}>
                        Shop Now
                        <ArrowForwardIcon sx={{ color: '#0A69D9', fontSize: '16px' }} />
                    </Button>
                </Box>
            )}
            <Grid
                xs={12}
                sx={{
                    ...Container,
                    marginTop: requiredPoints > 0 ? '-25px' : 0,
                }}
            >
                <Grid sx={Main}>
                    <Grid sx={PointMainGrid}>
                        <Grid sx={PointContainer}>
                            <Stack>
                                <Grid sx={PointGrid}>
                                    <Typography
                                        sx={{
                                            ...PointText,
                                            color: requiredPoints > 0 ? '#4F4C4D' : '',
                                        }}
                                    >
                                        {reward?.points_value || 0}
                                    </Typography>
                                    <Grid display={'flex'}>
                                        <Typography
                                            sx={{
                                                ...RewardsPointsLabel,
                                                color: requiredPoints > 0 ? '#4F4C4D' : '',
                                            }}
                                        >
                                            Points
                                        </Typography>
                                        {requiredPoints > 0 && (
                                            <Typography sx={{ margin: '-3px 4px 0px 4px' }}>
                                                <LockIcon
                                                    sx={{
                                                        color: '#7B7979',
                                                        fontSize: '14px',
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack justifyContent={'center'}>
                                <Stack>
                                    <ListItemText
                                        primary={reward?.name || ''}
                                        primaryTypographyProps={{
                                            color:
                                                requiredPoints > 0
                                                    ? '#606162'
                                                    : 'var(--Black, #14141E)',
                                            fontFamily: 'Euclid-Circular-A-Medium',
                                            fontSize: '1rem',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: '0.03125rem',
                                            textTransform: 'capitalize',
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    {isMyRewards && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                sx={{
                                    minWidth: '114px',
                                    height: '100%',
                                    padding: '15px 18px',
                                    background: isButtonInactive
                                        ? 'rgba(236, 236, 236, 0.40) !important'
                                        : 'rgba(10, 105, 217, 0.03) !important',
                                    color: isButtonInactive
                                        ? '#505050 !important'
                                        : '#0A69D9 !important',
                                    lineHeight: 'normal',
                                    cursor: isButtonInactive ? 'unset' : 'pointer',
                                    width: '100%',
                                    gap: isMobile ? '6px' : '3px',
                                }}
                                onClick={redeemPoints}
                            >
                                {reward.name.includes('service') ? (
                                    <>
                                        In Store{' '}
                                        <CustomTooltip
                                            title={
                                                'Please visit one of SniffyNose stores to redeem this reward'
                                            }
                                        >
                                            <InfoOutlinedIcon
                                                sx={{
                                                    fontSize: '1rem',
                                                    color: '#505050',
                                                    cursor: 'pointer',
                                                    marginTop: isMobile ? '' : '3px',
                                                }}
                                            />
                                        </CustomTooltip>
                                    </>
                                ) : (
                                    'Redeem'
                                )}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>

            {openModal && (
                <RedeemRewardModal
                    handleCouponCodeOpen={handleCouponCodeOpen}
                    handleClose={handleClose}
                    openModal={openModal}
                    selectedCouponCode={selectedCouponCode}
                    phone={user?.mobile || ''}
                    sendOTP={sendOTP}
                    sendOtpActionStatus={sendOTPFormMut.isPending}
                    setOpenCouponModal={setOpenCouponModal}
                    setCouponData={setCouponData}
                />
            )}

            {openCouponModal && (
                <RedeemSuccessModal
                    openModal={openCouponModal}
                    handleClose={handleCouponCodeClose}
                    handleOpen={handleCouponCodeOpen}
                    couponData={couponData}
                    isShowButtons={true}
                />
            )}
        </>
    );
}
